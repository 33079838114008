<template>
    <b-modal
        id="annotation-modal"
        hide-header
        hide-footer
        no-stacking
        centered
        size="lg"
    >
        <div class="header">
            <div class="header-content">
                <p>Adicionar anotação</p>
                <CloseIcon class="icon" @click="closeModal" />
            </div>
        </div>

        <div class="body">
            <div class="body-content">
                <b-form-textarea                    
                    id="annotation"
                    v-model="labpacs_item.annotations"
                    :disabled="isView"
                    rows="1"
                    max-rows="5"
                    no-resize
                    placeholder="Descrever"
                    maxlength="255"
                >
                
            </b-form-textarea>
            <div
                v-if="annotationValidator"
                class="custom-invalid-feedback"
              >
                Por favor, insira até 255 caracteres
              </div>
            </div>
        </div>
        
        <div class="footer" v-if="!isView">
            <div class="foot-content">
                <b-button
                    class="save-button"
                    variant="primary"
                    @click="saveAnnotation"
                >
                    Salvar
                </b-button>
            </div>
        </div>
    </b-modal>
</template>
<script>
export default {
    components: {
        CloseIcon: () => import('@/assets/icons/close.svg')
    },
    props: {
        labpacsItem: {
            type: Object,
            default: null
        },
        isView: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            labpacs_item: {
                annotations: ''
            },
        }
    },
    computed: {
        annotationValidator() {
            return this.labpacs_item.annotations.length > 254;
        }
    },
    methods: {
        closeModal() {
            this.$emit('close');
        },
        async saveAnnotation() {
            const isLoading = this.$loading.show();
            try {
                const response = await this.api.updateLabpacsItem(this.labpacs_item.id, {
                    annotations: this.labpacs_item.annotations
                });

                if (response.status >= 200 && response.status < 300) {
                    this.$toast.success('Anotação salva com sucesso')
                }
                
            } catch (error) {
                console.error(error)
                this.$toast.error('Erro ao salvar anotação');
                throw error

            } finally {
                isLoading.hide();
                this.closeModal();
            }
        }
    },
    watch: {
        labpacsItem: {
            handler: function(val) {
                if (this.labpacsItem) {
                    this.labpacs_item = this.labpacsItem;
                }
            },
            immediate: true
        }
    }
}
</script>

<style lang="scss" scoped>
.header {
    border-bottom: #D9DFF2 1px solid;
    .header-content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 13px 0;


        p {
            font-family: 'Nunito Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 18px;

            color: #525C7A;
        }


        svg {
          height: 24px;
          width: 24px;
          fill: #7F91D2;
          cursor: pointer;
        }
    }
}

.body .body-content {
    padding: 10px 0;
}

.footer  {
    border-top: #D9DFF2 1px solid;
    .foot-content {
        margin-top: 10px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }
}
.icon {
    cursor: pointer;
    width: 24px;
    height: 24px;    
}
</style>